import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import InputFields from './InputFields';
import EditInputField from './EditInputField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';

import {connect} from 'react-redux'
import { changeCheckBoxes } from '../../actions/backendDataAction'
import { updateCheckboxes } from '../../actions/answerModelAction'
import { ReactComponent as Question } from '../resources/img/question.svg';

import EditCheckBox from './EditCheckBox';


class CheckboxPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount(){
        
        this.setState({
          edit: false,
          activeOptions: [],
        })
    }

    changeEditMode(){
      this.setState({
        edit: !this.state.edit,
      })
    }
    
    addNewCheckBox(){
      const indexNumber = Object.keys(this.props.checkboxes).length;
      const name = "Titel " + indexNumber;
      const newCheckboxes = {...this.props.checkboxes, [name]: "Beskrivning"}
      
      this.props.changeCheckBoxes(newCheckboxes);
    }


    optionsCheckboxClicked(index, key) {

        let i = this.state.activeOptions.indexOf(index) 

        let oldState = this.state.activeOptions;
        if(i < 0){
            oldState.push(index)
            this.props.updateCheckboxes(key, true);
        } else{
            oldState.splice(i, 1)
            this.props.updateCheckboxes(key, false);
        }
        this.setState({
          activeOptions: oldState
        })

        
        
    }

    removeSelected(){
      const checkboxes = []
      Object.keys(this.props.checkboxes).map(function(keyName, keyIndex) {
        checkboxes.push({[keyName]: this.props.checkboxes[keyName]})
      }, this)
      let array = {}
      checkboxes.map((option, index) => {
        if(!this.state.activeOptions.includes(index)) {
          const key = Object.keys(option)[0];
          const description = option[key];
          array[key] = description;
        };
      })
      
      this.setState({
        activeOptions: []
      })

      this.props.changeCheckBoxes(array);
    }

    render() {
       const { edit, activeOptions } = this.state;
       const { canEdit } = this.props;
       const checkboxes = []
       Object.keys(this.props.checkboxes).map(function(keyName, keyIndex) {
         checkboxes.push({[keyName]: this.props.checkboxes[keyName]})
       }, this)

        if(!canEdit && checkboxes.length === 0) return null;

        return (
            <React.Fragment>
            {checkboxes && 
              <React.Fragment>
                  <Typography variant="h6" gutterBottom style={{paddingTop:20}}>
                      Övrig information
                  </Typography>
                  {edit ? 
                    <EditCheckBox 
                      checkboxes={this.props.checkboxes} 
                      editContent={(oldKey, event) => this.editContent(oldKey, event)}
                      editDescription={(oldKey, event) => this.editDescription(oldKey, event)}
                      changeEditMode={() => this.changeEditMode()}
                      />
                    :
                    <FormControl>
                      {checkboxes.map((checkbox, index) => {
                        const key = Object.keys(checkbox)[0];
                        const value = checkbox[key];
          
                        return <div style={{ display: 'flex', alignItems: 'center', marginTop: 20}}>
                          <FormControlLabel
                            value="start"
                            control={<Checkbox checked={activeOptions.includes(index)} color="primary" onChange={() => this.optionsCheckboxClicked(index, key)} />}
                            label={key}
                            labelPlacement="end"
                          />
                          <Tooltip title={
                            <React.Fragment>
                              <Typography variant="caption">{value}</Typography>
                            </React.Fragment>
                            }>
                            <Question />
                          </Tooltip>
                          </div>
                      })}
                      {canEdit && 
                        <div style={{ marginTop: 20 }}>

                          {edit ? 
                            <Button style={{cursor: 'pointer', marginRight: 10}} variant="outlined" color="primary" onClick={() => this.changeEditMode()}> Klar </Button>
                            :
                            <Button style={{cursor: 'pointer', marginRight: 10}} variant="outlined" color="primary" onClick={() => this.changeEditMode()}> Redigera </Button>
                          }
                          
                          <Button style={{cursor: 'pointer', marginRight: 10}} variant="outlined" color="secondary" disabled={activeOptions.length === 0} onClick={() => this.removeSelected()}> Ta bort markerade </Button>
                        </div>
                      }
                     
                      </FormControl>
                    }
                 
        

              </React.Fragment>
            }
              
            </React.Fragment>
        );
    }
}


const mapStateToProps = ({  }) =>  ({
        
  });

const mapDispatchToProps = (dispatch) => ({
    changeCheckBoxes: (checkBoxes) => dispatch(changeCheckBoxes(checkBoxes)),
    updateCheckboxes: (key, checked) => dispatch(updateCheckboxes(key, checked)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxPage)
