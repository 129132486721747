import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import {connect} from 'react-redux'
import { changeTimepointTasks, changeExtraTasks, changeSpecificTasks } from '../../actions/backendDataAction'

import { ReactComponent as Trashcan } from '../resources/img/trashcan.svg';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};



class EditInputField extends React.Component {

    constructor(props){
        super(props);
        
    }

    makeToArray(obj){
        let result = []
        Object.keys(obj).map((key) => {
            result.push(obj[key])
        })
        return result;
    }

    componentWillMount(){
        const { name, selections, standardTimes, selectionDescriptions } = this.props.values;
        this.setState({
            taskData: {
                ...this.props.values,
                selections: selections,
                standardTimes: this.makeToArray(standardTimes),
                selectionDescriptions: this.makeToArray(selectionDescriptions)
            },
            originalName: name
        })
         
    }

    changeTask(newData){
        this.props.changeTask(newData);
    }

    onChangeStateValue(event){
        this.setState({
            taskData: {
                ...this.state.taskData,
                [event.target.id]: event.target.value
            }
        })
    }

    onChangeStateChecked(event){
        this.setState({
            taskData: {
                ...this.state.taskData,
                [event.target.id]: event.target.checked
            }
        }, () => this.state.taskData)
    }

    onChangeSelectionName(event){
        /*
        selections: [],
        standardTimes: [],
        selectionDescription: [],
    

        */
        
        let selectionsList = this.state.taskData.selections;
        const index = event.target.id;
        selectionsList[index] = event.target.value;

        // const { [event.target.id]: _, ...oldStandardTimes } = this.state.taskData.standardTimes;
        // const newStandardTimes = {...oldStandardTimes, [event.target.value]: this.state.taskData.standardTimes[event.target.id]}

        // const { [event.target.id]: __, ...oldSelectionDescriptions } = this.state.taskData.selectionDescriptions;
        // const newSelectionDescriptions = {...oldSelectionDescriptions, [event.target.value]: this.state.taskData.selectionDescriptions[event.target.id]}

        this.setState({
            taskData: {
                ...this.state.taskData,
                selections: selectionsList,
            }
        })
    }

    onChangeStandardTime(event){
       //  const input = parseInt(event.target.value);
       //  let res;
       //  if(event.target.value === ""){
       //     res = event.target.value;
       // }else if(!Number.isNaN(input)){
       //     res = input;
       //}

        const input = event.target.value;
        const re_number = /^[+-]?(\d*\.)?\d+$/;
        const weight = Number(input); 
        let res;
        if (re_number.test(input)) {
            res = weight;
        } else {
            res = input;
        }
        let standardTimesList = this.state.taskData.standardTimes;
        const index = event.target.id;
        standardTimesList[index] = res;
        this.setState({
                taskData: {
                    ...this.state.taskData,
                    standardTimes: standardTimesList
                }
            })
    }

    onChangeSelectionDescriptions(event){
        let selectionDescriptionsList = this.state.taskData.selectionDescriptions;
        const index = event.target.id;
        selectionDescriptionsList[index] = event.target.value;
        this.setState({
            taskData: {
                ...this.state.taskData,
                selectionDescriptions: selectionDescriptionsList
            }
        })
    }

    formatToObject(){
        const { taskData } = this.state;
        let standardTimes = {};
        let selectionDescriptions = {};
        taskData.selections.map((taskName, i )=> {
            standardTimes = {...standardTimes, [taskName]: taskData.standardTimes[i]}
            selectionDescriptions = {...selectionDescriptions, [taskName]: taskData.selectionDescriptions[i]}
        })
        return {
            standardTimes: standardTimes,
            selectionDescriptions: selectionDescriptions,
        }  
    }

    validChanges(){
        let okay = true;
        const { description, selections, standardTimes, selectionDescriptions } = this.state.taskData;
        // Not duplicate care names
        if(this.checkDuplicateNames()) okay = false;

        // Has description
        if(description === "") okay = false;

        // Levels have names
        selections.map((item) => {
            if(item === "") okay = false;
        })

        // Levels have standardTimes
        standardTimes.map((item) => {
            if(item === "" || (typeof item) === "string") okay = false;
        })

        // Levels have descriptions
        selectionDescriptions.map((item) => {
            if(item === "") okay = false;
        })

        // Not duplicate level names
        let foundSelections = [];
        selections.map((item) => {
            if(foundSelections.includes(item)){
                okay = false;
            }
            foundSelections.push(item);
        })
        return okay;
    }

    saveChanges(oldName, timepoint){
        const formattedTimepoint = {
            ...timepoint,
            ...this.formatToObject()
        }
        this.props.changeTimePoint(oldName, formattedTimepoint)
        this.props.closeEdit();
    }

    removeTimepoint(timepoint){
        this.props.removeTimepoint(timepoint);
        this.props.closeEdit();
    }

    addLevel(){
        const newSelections = this.state.taskData.selections.concat("Ny nivå");
        const newTimes = this.state.taskData.standardTimes.concat(0);
        const newDescriptions = this.state.taskData.selectionDescriptions.concat("Beskrivning");
        this.setState({
            taskData: {
                ...this.state.taskData,
                selections: newSelections,
                standardTimes: newTimes,
                selectionDescriptions: newDescriptions
            }
        })
    }

    setSpecificTask(event){
        const { selectedMunicipaly, data } = this.props.backendDataReducer;
        const { specificExtraTasks } = data[selectedMunicipaly].calculationOutputSettings; 
        const key = event.target.id;
        let result = specificExtraTasks;
        let i = specificExtraTasks.indexOf(key) 
        if(i < 0){
            result.push(key)
        } else{
            result.splice(i, 1)
        }
        this.props.changeSpecificTasks(result);
    }

    removeLevel(i){
        const { selections, selectionDescriptions, standardTimes } = this.state.taskData;
        const taskName  = selections[i];
        const newSelections = selections;
        const newSelectionDescriptions = selectionDescriptions;
        const newStandardTimes = standardTimes;
        newSelections.splice(i, 1);
        delete newSelectionDescriptions[taskName];
        delete newStandardTimes[taskName];
        this.setState({
            taskData: {
                ...this.state.taskData,
                selections: newSelections,
                selectionDescriptions: newSelectionDescriptions,
                standardTimes: newStandardTimes,
            }
        })
    }

    changeFrequency(event){
        this.setState({
            taskData: {
                ...this.state.taskData,
                hasFrequency: event.target.checked
            }
        })
    }

    checkDuplicateNames(){
        let status = false;
        const { selectedMunicipaly, data } = this.props.backendDataReducer;
        const tasks = data[selectedMunicipaly].timepointTasks.concat(data[selectedMunicipaly].extraTasks)
        tasks.map((task) => {
            if(task.name === this.state.taskData.name && this.props.title !== this.state.taskData.name) status = true;
        })
        return status
    }

    checkDuplicateLevelNames(name, index){
        let status = false;
        this.state.taskData.selections.map((levelName, i) => {
            if(name === levelName && index !== i) status = true;
        })
        return status
    }

    render() {
        const { taskData } = this.state;
        const { extraTasks } = this.props;
        const { selectedMunicipaly, data } = this.props.backendDataReducer;
        const { specificExtraTasks } = data[selectedMunicipaly].calculationOutputSettings; 
        return (
            <Grid container spacing={3} style={{ borderBottomRadius: 1, borderBottomWidth: 1, borderBottomColor: 'black'}}>
                {taskData && 
             
                <React.Fragment>
                <Grid item xs={12} sm={1} />
                <Grid item xs={12} sm={7} style={{display: 'flex', flexDirection: 'column'}}>
                    {taskData.name === "" && <p style={{ alignSelf: 'flex-start', marginBottom: -10, fontSize: 14, color: 'red'}} >Får ej vara tom</p>}
                    {this.checkDuplicateNames() && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Får inte ha två insatser med samma namn</p>}
                    <TextField id="name" label="Namn" type="text" variant="outlined" value={taskData.name} style={{ marginTop: 16 }} onChange={(event) => this.onChangeStateValue(event)}/>
                    {taskData.description === "" && <p style={{ alignSelf: 'flex-start', marginBottom: -10, fontSize: 14, color: 'red'}} >Får ej vara tom</p>}
                    <TextField id="description" label="Livsområde" type="text" variant="outlined" value={taskData.description} style={{ marginTop: 16 }} onChange={(event) => this.onChangeStateValue(event)}/>
                     <FormControlLabel
                        style={{ marginTop: 10}}
                        control={<Checkbox checked={taskData.shared} id="shared" onChange={(event) => this.onChangeStateChecked(event)}  />}
                        label="Kan delas med make/maka"
                      />
                      {extraTasks && 
                         <React.Fragment>
                          <FormControlLabel
                            style={{ marginTop: 10}}
                            control={<Checkbox checked={taskData.hasFrequency} id="shared" onChange={(event) => this.changeFrequency(event)}  />}
                            label="Visa frekvensval"
                          />
                          <FormControlLabel
                            style={{ marginTop: 10}}
                            control={<Checkbox checked={specificExtraTasks.includes(taskData.name)} id={taskData.name} onChange={(event) => this.setSpecificTask(event)}  />}
                            label="Visa vid beräkning"
                          />
                          </React.Fragment>
                      }
                </Grid>
     

                <Grid item xs={12} sm={4}>
                <div style={{display: 'flex', flexDirection: 'column', width: 200}}>
                   <Button style={{ marginLeft: 50 }} disabled={!this.validChanges()} variant="outlined" color="primary" onClick={() => this.saveChanges(this.state.originalName, this.state.taskData)}>Lägg till ändringar</Button>
                   <Button style={{ marginLeft: 50, marginTop: 10 }} variant="outlined" color="secondary" onClick={() => this.removeTimepoint(this.state.taskData)}>Ta bort</Button>
                </div>
                </Grid>
                <Grid item xs={12} sm={1} />
                <Grid item xs={12} sm={9} style={{ borderBottomWidth: 1, borderBottomColor: '#cad8ff', borderBottomStyle: 'solid', marginBottom: 20}}>
                    {
                      taskData.selections.map((level, i) => {
                        return (
                            <div style={{marginBottom: 10}}>
                              <ExpansionPanel>
                                  <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography>{level}</Typography>
                                  </ExpansionPanelSummary>
                                    {level === "" && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Måste ha ett namn</p>}
                                    {(typeof taskData.standardTimes[i] === "string") && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Standardtid måste vara en siffra</p>}
                                    {taskData.selectionDescriptions[i] === "" && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Måste ha en beskrivning</p>}
                                    {this.checkDuplicateLevelNames(level, i) && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Får inte ha två nivåer med samma namn</p>}
                                  <ExpansionPanelDetails style={{justifyContent: 'space-evenly'}}>
                                        <TextField id={i} label="Namn" value={level} variant="outlined" onChange={(event) => this.onChangeSelectionName(event)}/>
                                        <TextField id={i} label="Standardtid (minuter)" value={taskData.standardTimes[i]} variant="outlined" onChange={(event) => this.onChangeStandardTime(event)}/>
                                        <TextField
                                          id={i}
                                          label="Beskrivning av nivå"
                                          multiline
                                          rowsMax="4"
                                          value={taskData.selectionDescriptions[i]}
                                          onChange={(event) => this.onChangeSelectionDescriptions(event)}
                                          variant="outlined"
                                        />
                                        {i !== 0 && <Trashcan style={{cursor: 'pointer', marginRight: -20}} onClick={() => this.removeLevel(i)}/>}
                                  </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        )
                        })
                    }
                    <Button style={{cursor: 'pointer', marginRight: 10, marginBottom: 20, marignTop: 20}} variant="outlined" color="primary" onClick={() => this.addLevel()}> Lägg till nivå</Button>
                </Grid>
                <Grid item xs={12} sm={2} />
                </React.Fragment>
                }
            </Grid>
        );
    }
}


const mapStateToProps = ({ backendDataReducer }) =>  ({
        backendDataReducer
  });

const mapDispatchToProps = (dispatch) => ({
    changeTimepointTasks: (oldName, timepointTasks) => dispatch(changeTimepointTasks(oldName, timepointTasks)),
    changeExtraTasks: (oldName, timepointTasks) => dispatch(changeExtraTasks(oldName, timepointTasks)),
    changeSpecificTasks: (task) => dispatch(changeSpecificTasks(task)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInputField)