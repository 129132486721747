import { 
  ADD_ALL_INPUT, 
  CLEAR_ALL, 
  ADD_INPUT, 
  CHANGE_TIMEPOINTTASKS, 
  CHANGE_EXTRATASKS, 
  CHANGE_SPECIFIC_TASKS,
  CHANGE_TIMEPOINTS, 
  CHANGE_SELECTED_MUNICIPALY,
  CHANGE_CHECKBOXES,
  CHANGE_WEIGHT,
  CHANGE_SETTINGS,
  CHANGE_LOGO_URL
} from '../actions/backendDataAction';

const initialState = {
    municipalies: [],
    selectedMunicipaly: "",
    data: {},
    settings: {},
    logoUrl: {}
  }

  
  const backendDataReducer = (state = initialState, action) => {
      switch (action.type) {

      	case 'ADD_ALL_INPUT':
          let names = []
          let inputs = {}
          let settings = {}
          let logoUrl = {}
          action.payload.map((item) => {
            names.push(item["name"]);
            inputs = {...inputs, [item["name"]]: item["input"]}
            logoUrl = {...logoUrl, [item["name"]]: item["logoUrl"]}
          })

      	  return {
            ...state,
            municipalies: names,
            data: inputs,
            logoUrl: logoUrl,
      	  }

        case 'CLEAR_ALL':

          return {
            ...initialState
          }

        case 'ADD_INPUT':
 
          const data = {
            [action.payload.key]: action.payload.data.input
          }

          const logoUrl2 = {
             [action.payload.key]: action.payload.data.logoUrl
          }
         
          return {
            ...state,
            selectedMunicipaly: action.payload.key,
            data: data,
            logoUrl: logoUrl2
          }


      	case 'CHANGE_TIMEPOINTS':

    			return {
            ...state,
    				data: {
              ...state.data, 
              [state.selectedMunicipaly]: {
                ...state.data[state.selectedMunicipaly],
                timepoints: action.payload,
              }
            }
    			}


      	case 'CHANGE_TIMEPOINTTASKS':
      	  return {
      	     ...state,
            data: {
              ...state.data, 
              [state.selectedMunicipaly]: {
                ...state.data[state.selectedMunicipaly],
                timepointTasks: action.payload,
              }
            }
      	  }

          case 'CHANGE_EXTRATASKS':
            return {
             ...state,
            data: {
              ...state.data, 
              [state.selectedMunicipaly]: {
                ...state.data[state.selectedMunicipaly],
                extraTasks: action.payload,
              }
            }
          }

          case 'CHANGE_SPECIFIC_TASKS':
            return {
             ...state,
            data: {
              ...state.data, 
              [state.selectedMunicipaly]: {
                ...state.data[state.selectedMunicipaly],
                calculationOutputSettings: {
                  ...state.data[state.selectedMunicipaly].calculationOutputSettings,
                  specificExtraTasks: action.payload,
                }
              }
            }
          }


          case 'CHANGE_SELECTED_MUNICIPALY':
            return {
              ...state,
              selectedMunicipaly: action.payload,
            }

          case 'CHANGE_CHECKBOXES':
            return {
              ...state,
              data: {
                ...state.data,
                [state.selectedMunicipaly]: {
                  ...state.data[state.selectedMunicipaly],
                  checkboxes: action.payload,
                }
              }
            }

          case 'CHANGE_WEIGHT':
            const oldWeights = state.data[state.selectedMunicipaly].weights;
            const newWeights = {...oldWeights, [action.payload.name]: action.payload.value}
            return {
              ...state,
              data: {
                ...state.data,
                [state.selectedMunicipaly]: {
                  ...state.data[state.selectedMunicipaly],
                  weights: newWeights,
                }
              }
            }

          case 'CHANGE_SETTINGS':
          const oldSettings = state.data[state.selectedMunicipaly][action.payload.key];
          const newSettings = {
            ...oldSettings, 
            [action.payload.settingsName]: action.payload.value
          } 
          return {
            ...state,
            data: {
              ...state.data,
              [state.selectedMunicipaly]: {
                ...state.data[state.selectedMunicipaly],
                [action.payload.key]: newSettings
              },
            }
          }

          case 'CHANGE_LOGO_URL':

          return {
              ...state,
              logoUrl: {
              ...state.logoUrl,
              [state.selectedMunicipaly]: action.payload
            }
          }
          
        default:
          return {
            ...state
          }
      }
    }
    
    export default backendDataReducer
