import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import FormPage from '../partials/FormPage'
import EditTime from '../partials/EditTime'
import CheckboxPage from '../partials/CheckboxPage'
import EditLogoUrl from '../partials/EditLogoUrl'
import ShareOptionsPage from '../partials/ShareOptionsPage'


import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

import sigtuna from '../resources/img/Sigtuna.png';
import osteraker from '../resources/img/Österåker.png';

import { ReactComponent as Question } from '../resources/img/question.svg';
import { ReactComponent as Pen } from '../resources/img/pen.svg';

import {connect} from 'react-redux'
import { addAllData, addInput, changeTimepoints, changeSelectedMunicipaly, changeWeights, changeSettings, changeLogoUrl, clearAllData } from '../../actions/backendDataAction'

import { ReactComponent as Logo } from '../../img/plus-logo.svg';
import myData from '../resources/testData/testdata.json';
import { saveAs } from 'file-saver';

import apiConfig from "../resources/config/api-config.json";

//const URL = apiConfig.development;
const URL = apiConfig.live;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">   
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()} 
      {'.'}
    </Typography>
  );
}


function buildInput(input){
    let obj = {}
    input.timepoints.map((timepoint) => {
        let timeInputObj = {}
        
        input.timepointTasks.map((timepointTask) => {            
            timeInputObj[timepointTask.name] = {perWeek:0, selection:Object.keys(timepointTask.selectionDescriptions)[0]}
        })
        obj[timepoint] = timeInputObj
    })
    let extraObj = {}
    input.extraTasks.map((task) => {
        extraObj[task.name] = ""
    })
    return {
        municipality: "Stockholm",
        vaxelvard: 0,
        delad: false,
        extraTasks: extraObj,
        timepointTasks:obj
    }
}

const classes = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});




class Admin extends React.Component {

	state = {
		answerModel:{},
		activeSteps: [],
		timePerTimepoint:{},
		editTime: false,
		selectKey: "",
		showBackdrop: false,
		newMunicipalityName: "",
		editUrl: false,
		showErrorModal: false,
		saveButtonTextColor: '#3f51b5',
		saveButtonBackgroundColor: 'white',
		saveButtonText: 'Spara ändringar'
	}

	componentDidMount(){
	    //this.props.addNames(myData);
	    this.getAllData();
	}

	getAllData(){
	

		fetch(URL + "/municipalities", { 
			headers: new Headers({
			     'Authorization': 'Bearer ' + this.props.token,
			   })
		})
		  .then(res => res.json())
		  .then(
		    (result) => {
		      this.props.addAllData(result);
		    },
		    // Note: it's important to handle errors here
		    // instead of a catch() block so that we don't swallow
		    // exceptions from actual bugs in components.
		    (error) => {
		      console.log("error", error)
		    }
		 )
	}

	// new data = new timepoints
	changeTimepoints(newTimepoints){
		this.props.changeTimepoints(newTimepoints);
	}


	getHours = (num) => {
	    return Math.floor(num);
	}

	getMinutes = (num) => {
	    return Math.floor((num - this.getHours(num)) * 60);
	}

	createTimeString = (hours) => {
	    return "" + this.getHours(hours) + "h " + this.getMinutes(hours) + "min"
	}
	

	performCalculation = (obj) => {

	    // axios.post("https://inrikta.herokuapp.com/input?kommun=" + this.props.role, this.state.answerModel)
	    // .then((res) => {
	    //     this.setState({
	    //         res:this.createTimeString(res.data.totalHoursPerMonth),
	    //         hoursPerWeek: this.createTimeString(res.data.hoursPerWeekPersonligVard),
	    //         hoursPerMonth:this.createTimeString(res.data.hoursPerMonthPersonligVard),
	    //         hoursPerMonthExtraTasks: this.createTimeString(res.data.hoursPerMonthExtraTasks),
	    //         timePerTimepoint:res.data.hoursPerMonthSpecificTasks})
	    // })
	    // .catch((e) => {
	    //     return "Error, no response recieved from server"
	    // })
	}

	timeStepCheckboxClicked = (key) => {
	    let i = this.state.activeSteps.indexOf(key) 
	    if(i < 0){
	        this.state.activeSteps.push(key)
	    } else{
	        this.state.activeSteps.splice(i, 1)
	    }

	    this.setState({
	        update:!this.state.update
	    })
	}

    

	renderCheckBoxes(data){
		return (
			data.map((key) => {
			    return (
			        <FormControlLabel
			            value="end"
			            control={<Checkbox checked={this.state.activeSteps.includes(key)} color="primary" onChange={() => {this.timeStepCheckboxClicked(key)}} />}
			            label={key}
			            labelPlacement="end"
			        />
			    )
			})
		)
	}

    renderSummary(){

    	return (
    		<Typography variant="subtitle1">
    		{this.state.res ? (<Typography><b>Totalt antal timmar per månad: {this.state.res}</b></Typography>) : null } 
    		<br />
    		{this.state.hoursPerMonth ? (<Typography>Timmar personlig vård per månad: {this.state.hoursPerMonth}</Typography>) : null } 
    		<br />
    		{this.state.hoursPerWeek ? (<Typography>Timmar personlig vård per vecka: {this.state.hoursPerWeek}</Typography>) : null } 
    		<br />
    		{this.state.timePerTimepoint["Inköp, bank, post och apoteksärenden"] ? (<Typography>Timmar inköp per månad: {this.createTimeString(this.state.timePerTimepoint["Inköp, bank, post och apoteksärenden"])}</Typography>) : null } 
    		<br />
    		{this.state.timePerTimepoint["Tvätt"] ? (<Typography>Timmar tvätt per månad: {this.createTimeString(this.state.timePerTimepoint["Tvätt"])}</Typography>) : null } 
    		<br />
    		{this.state.timePerTimepoint["Städning"] ? (<Typography>Timmar städning per månad: {this.createTimeString(this.state.timePerTimepoint["Städning"])}</Typography>) : null } 
    		<br />
    		{this.state.timePerTimepoint["Aktivitet/promenad"] ? (<Typography>Timmar aktivitet per månad: {this.createTimeString(this.state.timePerTimepoint["Aktivitet/promenad"])}</Typography>) : null } 
    		</Typography>	
    	)
    }

    showEditTime(){
    	this.setState({
    		editTime: !this.state.editTime,
    	})
    }

    removeSelected(){
    	const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    	const newTimepoints = this.props.backendDataReducer.data[selectedMunicipaly].timepoints.filter((item) => !this.state.activeSteps.includes(item));
    	this.setState({
    		activeSteps: []
    	})
    	this.changeTimepoints(newTimepoints);
    }

    saveTimepoint(text){
    	const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    	const newTimepoints = this.props.backendDataReducer.data[selectedMunicipaly].timepoints.concat(text);
    	this.changeTimepoints(newTimepoints);
    	this.showEditTime();
    }

    selectMunicipaly(event){
    	this.setState({
    		selectKey: event.target.value
    	})
    	this.props.changeSelectedMunicipaly(event.target.value);
    }

    saveToBackend(){
    	const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    	const data = this.props.backendDataReducer.data[selectedMunicipaly];
    	const logoUrl = this.props.backendDataReducer.logoUrl[selectedMunicipaly]
    	
		const dataOut = {name: selectedMunicipaly, logoUrl: logoUrl, input: data}
    	axios.post(URL + "/municipalities", dataOut, { 
			headers: {
			     'Authorization': 'Bearer ' + this.props.token,
			   }
		})
    	.then((res) => {
	        //console.log("post result", res)
	        this.setState({ 
	        	saveButtonTextColor: 'white',
			    saveButtonBackgroundColor: '#7cb342',
			    saveButtonText: 'Sparat!'
	        });
	        setTimeout(
			    function() {
			        this.setState({ 
			        	saveButtonTextColor: '#3f51b5',
						saveButtonBackgroundColor: 'white',
						saveButtonText: 'Spara ändringar'
			        });
			    }
			    .bind(this),
			    1500
			);
	    })
	    .catch((e) => {
	    	this.setState({ showErrorModal: true})
	        return "Error, no response recieved from server"
	    })
    }

    changeWeight(name, event){

    	const input = event.target.value;
		const re_number = /^[+-]?(\d*\.)?\d+$/;
		const weight = Number(input); 

		if (re_number.test(input)) {
			this.props.changeWeights(name, weight);
		} else {
			this.props.changeWeights(name, input);
		}

     //    const input = event.target.value;
     //    const weight = Number(input); 
     //    console.log(parseFloat(weight))
	    // if(!Number.isNaN(weight)){
     // 		this.props.changeWeights(name, event.target.value);
     // 	}
    }

    addNewMunicipality(){
    	const result = {
    		name: this.state.newMunicipalityName,
    		logoUrl: "",
    		input: {
    			timepoints: [],
    			timepointTasks: [],
    			extraTasks: [],
    			checkboxes: {},
    			weights: {
    				alpha: 2,
                	beta: 2
    			},
    			"formSettings": {
		            "showWeeksOff": false,
	                "showShared": false,
	                "nameWeeksOff": "Växelvård",
	                "nameShared": "Dela med make/maka",
	                "descriptionWeeksOff": "<BESKRIVNING>",
	                "descriptionShared": "<BESKRIVNING>",
	                "showDescription": false
		        },
		        "calculationOutputSettings": {
		            "showTimepoints": false,
		            "specificExtraTasks": []
		        }
    		},
    		
    	}
    	
    	axios.post(URL + "/municipalities", result, { 
			headers: {
			     'Authorization': 'Bearer ' + this.props.token,
			   }
		})
    	.then((res) => {
	        //console.log("post result", res)
	        this.getAllData();
	    })
	    .catch((e) => {
	    	this.setState({ showErrorModal: true})
	        return "Error, no response recieved from server"
	    })
	    this.setState({
	    	newMunicipalityName: "",
	    	showBackdrop: false,
	    })
    }

    changeSettings(key, settingName, value){
    	this.props.changeSettings(key, settingName, value);
    }

    changeLogoUrl(event){
    	this.props.changeLogoUrl(event.target.value)
    }

    editUrl(){
    	this.setState({
    		editUrl: !this.state.editUrl
    	})
    }

    saveStateToFile(){
    	const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    	const data = this.props.backendDataReducer.data[selectedMunicipaly];
    	const logoUrl = this.props.backendDataReducer.logoUrl[selectedMunicipaly]
		const dataOut = {name: selectedMunicipaly, logoUrl: logoUrl, input: data}
    	
    	const saveData = {
    		dataReducer: this.props.backendDataReducer,
    		saveFormat: dataOut,
    	}
    	var file = new Blob([JSON.stringify(saveData)], {
			type: "text/plain;charset=utf-8"
		});
		saveAs(file, "state.json");
    }


	render() {
	
		const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
		
		const data = this.props.backendDataReducer.data[selectedMunicipaly];
		const logoUrl = this.props.backendDataReducer.logoUrl[selectedMunicipaly];
		let timepoints;
		let timepointTasks;
		let extraTasks;
		let checkboxes;
		if(data){
			timepoints = data.timepoints;
			timepointTasks = data.timepointTasks;
			extraTasks = data.extraTasks;
			checkboxes = data.checkboxes;
		}
		const { municipalies } = this.props.backendDataReducer;
		const re_number = /^(\d+\.)?\d+$/;
	  	return (
			<React.Fragment>
			
				<Modal
			        aria-labelledby="simple-modal-title"
			        aria-describedby="simple-modal-description"
			        open={this.state.showBackdrop}
			        onClose={() => this.setState({ showBackdrop: false })}
			      >	        
						<Paper style={{ 
							marginTop: 200, 
							marginLeft: 'auto', 
							marginRight: 'auto', 
							width: 400, 
							height: 300, 
							zIndex: 2000,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-around',
							paddingLeft: 40,
							paddingRight: 40,
						}}>
							<Typography variant="h6" gutterBottom align="center" style={{paddingTop: 20}}>Lägg till ny kommun</Typography>
							<TextField id={"hej"} label="Namn" value={this.state.newMunicipalityName} variant="outlined" onChange={(event) => this.setState({ newMunicipalityName: event.target.value })}/>
							<Button style={{cursor: 'pointer', backgroundColor: 'white'}} variant="outlined" color="primary" onClick={() => this.addNewMunicipality()}> Lägg till </Button>

						</Paper>

				     </Modal>
				<Modal
			        aria-labelledby="simple-modal-title"
			        aria-describedby="simple-modal-description"
			        open={this.state.showErrorModal}
			        onClose={() => this.setState({ showErrorModal: false })}
			      >	        
						<Paper style={{ 
							marginTop: 200, 
							marginLeft: 'auto', 
							marginRight: 'auto', 
							width: 400, 
							height: 300, 
							zIndex: 2000,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-around',
							paddingLeft: 40,
							paddingRight: 40,
						}}>
							<Typography variant="h6" gutterBottom align="center" style={{paddingTop: 20}}>Ett fel har inträffat, <br />Något är inte ifyllt korrekt</Typography>
							<Button style={{cursor: 'pointer', backgroundColor: 'white'}} variant="outlined" color="primary" onClick={() => this.setState({ showErrorModal: false })}> Stäng </Button>


						</Paper>

				     </Modal>
			{data ? 
				<React.Fragment>
				
				<CssBaseline />
				<main className={classes.layout}>
					<div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 100}}>
						

						<Button style={{width: 180, cursor: 'pointer', marginRight: 10, borderColor: this.state.saveButtonTextColor, backgroundColor: this.state.saveButtonBackgroundColor, color: this.state.saveButtonTextColor}} variant="outlined" color="primary" onClick={() => this.saveToBackend()}>{this.state.saveButtonText}</Button>
					</div>
					<img src={logoUrl} className="App__logo" />
					<Paper className={classes.paper} style={{ width: 1000}}>
						<Grid container spacing={3}>
						<Grid item xs={12} sm={4}>
							<Button style={{cursor: 'pointer', marginRight: 10, backgroundColor: 'white'}} variant="outlined" color="primary" onClick={() => this.setState({ showBackdrop: true })}> Lägg till ny kommmun </Button>
						</Grid>
						<Grid item xs={12} sm={4}>	
							<Typography component="h1" variant="h4" align="center" style={{paddingTop: 20}}>Admin portal</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControl>
								<InputLabel htmlFor="age-native-simple">Välj kommun</InputLabel>
								<Select
									native
									style={{width: 200}}
									value={this.state.selectKey}
									onChange={(event) => this.selectMunicipaly(event)}
									inputProps={{
										name: 'age',
										id: 'age-native-simple',
									}}
								>
								<option value="" />
								{municipalies.map((item) => {
									return <option value={item}>{item}</option>
								})}
								</Select>
							</FormControl>
						</Grid>
						</Grid>
						<React.Fragment>
							<div style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
							}}>
								<div style={{
									marginTop:20, 
									minWidth: 310,
									display: 'flex',
									flexDirection: 'column',
								}}>
									{this.renderCheckBoxes(timepoints)}
									<div style={{
										display: 'flex',
									    justifyContent: 'center',
									    minHeight: 70,
									    alignItems: 'center',
								
									}}>
										{this.state.editTime ? 
											<EditTime 
												close={() => this.showEditTime()} 
												save={(text) => this.saveTimepoint(text)}
												timepoints={timepoints}
												/> 
											:
											<div>
												<Button style={{cursor: 'pointer', marginRight: 10}} variant="outlined" color="primary" onClick={() => this.showEditTime()}> Lägg till </Button>
												<Button style={{cursor: 'pointer'}} disabled={this.state.activeSteps.length === 0} variant="outlined" color="secondary" onClick={() => this.removeSelected()}> Ta bort markerade </Button>
											</div>
										}
									</div>
								</div>
							</div>
							<React.Fragment>
								<FormPage 
									dontShowLevels={false} 
									title={"Personlig vård"} 
									values={timepointTasks}
									ordinary
									canEdit
									currentSelected={selectedMunicipaly}
								/>
								<FormPage 
									dontShowLevels={false}
									title={"Övriga insatser"}
									values={extraTasks}
									canEdit
									extraTasks
									currentSelected={selectedMunicipaly}
								/>
							</React.Fragment>
						</React.Fragment>
						<Grid container spacing={3}>
						<Grid item xs={12} sm={3}></Grid>
						<Grid item xs={12} sm={6}>
						{data.formSettings.showShared && 
							<ShareOptionsPage admin settings={data.formSettings} />
						}
						<CheckboxPage canEdit checkboxes={checkboxes} />
						
						
						</Grid>
						<Grid item xs={12} sm={3}></Grid>
						</Grid>

						<React.Fragment>
						{!re_number.test(data.weights.alpha) && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Alpha är av fel format</p>}
						{re_number.test(data.weights.alpha) && Number(data.weights.alpha) <= 1.0 && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Alpha måste vara ett tal större än 1</p>}
						{!re_number.test(data.weights.beta) && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Beta är av fel format</p>}
						{re_number.test(data.weights.beta) && Number(data.weights.beta) <= 0.0 && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Beta måste vara ett tal större än 0</p>}

							<div style={{ marginTop: 20}}>
								<TextField 
								  variant="outlined" 
								  style={{ marginRight: 20}} 
								  id={"alpha"} 
								  label={"alpha weight"} 
								  value={data.weights.alpha}
								  onChange={(event) => this.changeWeight("alpha", event)}
								/>
								<TextField 
								  variant="outlined" 
								  style={{ marginRight: 20}} 
								  id={"beta"} 
								  label={"beta weight"} 
								  value={data.weights.beta} 
								  onChange={(event) => this.changeWeight("beta", event)}
								/>
							</div>
						</React.Fragment>
						<Grid container spacing={3}>
						<Grid item xs={12} sm={3}></Grid>
						<Grid item xs={12} sm={6}>
							<React.Fragment>
								<FormControl style={{display: 'flex', paddingLeft: 40, maginTop: 10}}>
									<Typography variant="h6" gutterBottom style={{paddingTop:20}}>
									    Inställningar
									</Typography>
									<FormControlLabel
									  value="start"
									  control={<Checkbox checked={data.formSettings.showDescription} color="primary" onChange={(event) => this.changeSettings("formSettings", "showDescription", event.target.checked)} />}
									  label={"Visa livsområden"}
									  labelPlacement="end"
									/>
									<FormControlLabel
									  value="start"
									  control={<Checkbox checked={data.formSettings.showShared} color="primary" onChange={(event) => this.changeSettings("formSettings", "showShared", event.target.checked)} />}
									  label={"Får dela vård med make/maka"}
									  labelPlacement="end"
									/>
									<FormControlLabel
									  value="start"
									  control={<Checkbox checked={data.calculationOutputSettings.showTimepoints} color="primary" onChange={(event) => this.changeSettings("calculationOutputSettings", "showTimepoints", event.target.checked)} />}
									  label={"Visa tidpunkter i beräkning"}
									  labelPlacement="end"
									/>
									{this.state.editUrl ? 
										<EditLogoUrl logoUrl={logoUrl} closeEdit={() => this.editUrl()}/>
										:
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20}}>
											<TextField disabled={true} id="Logo url" label="Logo url" type="text" variant="outlined" value={logoUrl} style={{  width: 400 }} onChange={(event) => console.log()}/>
											<Button style={{ marginLeft: 10, height: 56 }} variant="outlined" color="primary" onClick={() => this.editUrl()}>Ändra</Button>
										</div>
									}
								</FormControl>
							</React.Fragment>
						</Grid>
						<Grid item xs={12} sm={3}></Grid>
						</Grid>
						<React.Fragment>
							<div style={{marginTop:20, paddingBottom: 20}}>
								{/*<Button variant="outlined" color="primary" onClick={this.performCalculation}>Beräkna</Button>*/}
								{this.renderSummary()}
								{/*<Button variant="outlined" color="primary" onClick={() => this.saveStateToFile()}>Spara data till utvecklare</Button>*/}
							</div>
						</React.Fragment>
					</Paper>
				</main>
				</React.Fragment>
				:
				<React.Fragment>
				<CssBaseline />
				<main className={classes.layout}>
					<Paper className={classes.paper} style={{ minWidth: 1000, minHeight: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
						<Typography component="h1" variant="h4" align="center" style={{paddingBottom: 20}}>Admin portal</Typography>
					  	 <FormControl>
					        <InputLabel htmlFor="age-native-simple">Välj kommun</InputLabel>
					        <Select
					          native
					          style={{width: 200}}
					          value={this.state.selectKey}
			          		  onChange={(event) => this.selectMunicipaly(event)}
					          inputProps={{
					            name: 'age',
					            id: 'age-native-simple',
					          }}
					        >
					        <option value="" />
					        {municipalies.map((item) => {
					        	return <option value={item}>{item}</option>
					        })}
					        </Select>
					      </FormControl>
					      <Button style={{cursor: 'pointer', marginTop: 40, backgroundColor: 'white'}} variant="outlined" color="primary" onClick={() => this.setState({ showBackdrop: true })}> Lägg till ny kommmun </Button>

					</Paper>
				</main>
				</React.Fragment>
			}
			</React.Fragment>
			);
	}
}

const mapStateToProps = ({ backendDataReducer, answerModelReducer }) =>  ({
    backendDataReducer,
    answerModelReducer
  });

const mapDispatchToProps = (dispatch) => ({
    addAllData: (data) => dispatch(addAllData(data)),
    addInput: (key, input) => dispatch(addInput(key, input)),
    changeTimepoints: (data) => dispatch(changeTimepoints(data)),
    changeSelectedMunicipaly: (name) => dispatch(changeSelectedMunicipaly(name)),
    changeWeights: (name, value) => dispatch(changeWeights(name, value)),
    changeSettings: (key, settingName, value) => dispatch(changeSettings(key, settingName, value)),
    changeLogoUrl: (value) => dispatch(changeLogoUrl(value)),
    clearAllData: () => dispatch(clearAllData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(Admin))


