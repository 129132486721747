import React from 'react';
import './App.css';
import {connect} from 'react-redux'

import Admin from './components/pages/Admin';
import Client from './components/pages/Client';



import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';

import redirectConfig from "./components/resources/config/redirect-config.json";


import { useAuth0 } from "./react-auth0-spa";




function App() {

	//const redirectURL = apiConfig.development;
	//const redirectURL = redirectConfig.live;
	const s = window.location.href
	const redirectURL = s.substring(0, s.length - 1)

	const [adminFocusClient, setFocus] = React.useState(false);

	const { isAuthenticated, loginWithRedirect, logout, loading, user, accessToken } = useAuth0();
	//console.log(redirectURL)

	let isAdmin = false
	let role;


	if(user) {
		if(user["https://beslutsstod.inrikta.se/roles"].includes("Admin")) {
			isAdmin = true;
		}else{
			role = user["https://beslutsstod.inrikta.se/roles"][0]
		}
	}
	if(loading){
	  return <div>{isAuthenticated}</div>
	}
	if(!isAuthenticated){
	    loginWithRedirect()
	  }


	const handleChange = event => {
	    setFocus(!adminFocusClient);
	
	  };

	return (
		<div className="App">
		  <header className="App-header">
		  <div style={{ display: 'flex', justifyContent: 'space-around', width: "50%", paddingTop: 20}}>
		  	</div>
		  	{isAuthenticated && 
			  	<React.Fragment>
			  	{isAdmin ? 
			  		<Admin role={role} token={accessToken} />
			  		:
			  		<Client role={role} token={accessToken}/>
			  	}
			  	<Button style={{cursor: 'pointer', backgroundColor: 'white', marginBottom: 20}} variant="outlined" color="primary" onClick={() => logout({ returnTo: redirectURL })}> Logga ut </Button>
			  	</React.Fragment>
		  	}
		  </header>
		</div>
	);
}

export default App;
