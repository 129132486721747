import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import InputFields from './InputFields';
import EditInputField from './EditInputField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';

import {connect} from 'react-redux'
import { changeTimepointTasks, changeExtraTasks, changeCheckBoxes } from '../../actions/backendDataAction'
import { ReactComponent as Question } from '../resources/img/question.svg';


class EditCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.editContent = this.editContent.bind(this);
    }

    objectToList(object){
      let titles = []
      let descriptions = []
      Object.keys(object).map((item) => {
        titles.push(item);
        descriptions.push(object[item]);
      })
      return {
        titles: titles,
        descriptions: descriptions,
      }
    }

    componentWillMount(){
        this.setState({
          edit: false,
          checkboxes: this.objectToList(this.props.checkboxes),
        })
    }

    changeEditMode(){
      this.setState({
        edit: !this.state.edit,
      })
    }

    editContent(event){
     
      let newTitles = JSON.parse(JSON.stringify(this.state.checkboxes.titles));
      newTitles[event.target.id] = event.target.value;
      this.setState({
        checkboxes: {
          titles: newTitles,
          descriptions: this.state.checkboxes.descriptions
        },
      })
    }

    editDescription(event){
      let newDescriptions = JSON.parse(JSON.stringify(this.state.checkboxes.descriptions));
      newDescriptions[event.target.id] = event.target.value;
      this.setState({
        checkboxes: {
          titles: this.state.checkboxes.titles,
          descriptions: newDescriptions
        },
      })
    }

    addNewCheckBox(){
      let newTitles = JSON.parse(JSON.stringify(this.state.checkboxes.titles));
      let newDescriptions = JSON.parse(JSON.stringify(this.state.checkboxes.descriptions));
      newTitles.push("Ny titel")
      newDescriptions.push("Ny Beskrivning")
      this.setState({
        checkboxes: {
          titles: newTitles,
          descriptions: newDescriptions,
        }
      })
    }

    updateCheckBoxes(){
      let result = {}
      this.state.checkboxes.titles.map((title, index) => {
        result = {...result, [title]: this.state.checkboxes.descriptions[index]}
      })
      this.props.changeCheckBoxes(result)
      this.props.changeEditMode();
    }

    checkForDuplicateTitles(){
      let okay = false;
      let found = [];
      this.state.checkboxes.titles.map((t) => {
        if(found.includes(t)) okay = true;
        found.push(t);

      })
      return okay;
    }

    render() {
      
       const { checkboxes } = this.state;
        return (
            <React.Fragment>
                  <FormControl>
                  {checkboxes.titles.map((title, index) => {
                        const key = title
                        const description = checkboxes.descriptions[index];
                        return (
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20}}>


                          <TextField 
                            variant="outlined" 
                            style={{ marginRight: 20}} 
                            id={index} 
                            label={"Titel"} 
                            value={key} 
                            onChange={(event) => this.editContent(event)}
                          />
                          <TextField 
                            variant="outlined" 
                            multiline 
                            rowsMax="4" 
                            id={index} 
                            label={"Beskrivning"} 
                            value={description} 
                            onChange={(event) => this.editDescription(event)}
                          />
                        </div>)
                      })}
                    {this.checkForDuplicateTitles() && <p style={{ alignSelf: 'center', marginBottom: 10, fontSize: 14, color: 'red'}} >Titeln måste vara unik</p>}
                     <div style={{ marginTop: 20 }}>
                      <Button style={{cursor: 'pointer', marginRight: 10}} variant="outlined" color="primary" onClick={() => this.addNewCheckBox()}> Lägg Till </Button>
                      <Button style={{cursor: 'pointer', marginRight: 10}} disabled={this.checkForDuplicateTitles()} variant="outlined" color="primary" onClick={() => this.updateCheckBoxes()}> Klar </Button>
                    </div>
                  </FormControl>

              
            </React.Fragment>
        );
    }
}


const mapStateToProps = ({  }) =>  ({
        
  });

const mapDispatchToProps = (dispatch) => ({
    changeTimepointTasks: (oldName, timepointTasks) => dispatch(changeTimepointTasks(oldName, timepointTasks)),
    changeExtraTasks: (oldName, timepointTasks) => dispatch(changeExtraTasks(oldName, timepointTasks)),
    changeCheckBoxes: (checkboxes) => dispatch(changeCheckBoxes(checkboxes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCheckbox)
