import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import InputFields from './InputFields';
import EditInputField from './EditInputField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {connect} from 'react-redux'
import { changeSettings } from '../../actions/backendDataAction'
import { ReactComponent as Question } from '../resources/img/question.svg';

import EditCheckBox from './EditCheckBox';


class ShareOptionsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount(){
        const { settings } = this.props;
        this.setState({
          edit: false,
          nameWeeksOff: settings.nameWeeksOff,
          descriptionWeeksOff: settings.descriptionWeeksOff,
          nameShared: settings.nameShared,
          descriptionShared: settings.descriptionShared,
        })
    }

    changeEditMode(){
      this.setState({
        edit: !this.state.edit,
      })
    }

    onChangeInput(event){
      this.setState({
        [event.target.id]: event.target.value
      })
    }

    saveToRedux(){
      const { nameWeeksOff, descriptionWeeksOff, nameShared, descriptionShared } = this.state;
      this.props.changeSettings("formSettings", "nameWeeksOff", nameWeeksOff);
      this.props.changeSettings("formSettings", "descriptionWeeksOff", descriptionWeeksOff);
      this.props.changeSettings("formSettings", "nameShared", nameShared);
      this.props.changeSettings("formSettings", "descriptionShared", descriptionShared);
      this.changeEditMode();
    }

    updateWeeksOff(event){
      const { updateWeeksOff, admin } = this.props;
      if(!admin){
        updateWeeksOff(event);
      }
    }

    updateShared(event){
      const { updateShared, admin } = this.props;
      if(!admin){
        updateShared(event);
      }
    }

    render() {
       const { settings, admin, answerModelReducer } = this.props;
       const { edit, nameWeeksOff, descriptionWeeksOff, nameShared, descriptionShared } = this.state;
        return (
            <React.Fragment>
              <div style={{marginTop:30}}>
               <Typography variant="h6" gutterBottom style={{paddingTop:20}}>
                      Delad vård
                  </Typography>
              <FormControl>
              {nameWeeksOff === "" && <p style={{ alignSelf: 'flex-start', marginBottom: -10, fontSize: 14, color: 'red'}} >Text får inte vara tom</p>}
              {descriptionWeeksOff === "" && <p style={{ alignSelf: 'flex-start', marginBottom: -10, fontSize: 14, color: 'red'}} >Beskrivning får inte vara tom</p>}
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 20}}>
              {edit ? 
                <React.Fragment>
                  <TextField style={{ marginRight: 10 }} id={"nameWeeksOff"} label="Text" value={nameWeeksOff} variant="outlined" onChange={(event) => this.onChangeInput(event)}/>
                  <TextField id={"descriptionWeeksOff"} multiline rowsMax="4" label="Beskrivning" value={descriptionWeeksOff} variant="outlined" onChange={(event) => this.onChangeInput(event)}/>
                </React.Fragment>
                :
                <React.Fragment>
                <FormControlLabel
                  control={
                    <Select
                      style={{ marginLeft: 10, marginRight: 10}}
                      placeholder=""
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={answerModelReducer.weeksOff}
                      onChange={(event) => this.updateWeeksOff(event)}
                    >
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
       
                    </Select>
                  } 
                  label={settings.nameWeeksOff}
                  labelPlacement="end"
                />
                <Tooltip title={
                        <React.Fragment>
                          <Typography variant="caption">{settings.descriptionWeeksOff}</Typography>
                        </React.Fragment>
                      }>
                    <Question />
                  </Tooltip>
                </React.Fragment>
              }
                </div>
                {nameShared === "" && <p style={{ alignSelf: 'flex-start', marginBottom: -10, fontSize: 14, color: 'red'}} >Text får inte vara tom</p>}
                {descriptionShared === "" && <p style={{ alignSelf: 'flex-start', marginBottom: -10, fontSize: 14, color: 'red'}} >Beskrivning får inte vara tom</p>}
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20}}>
                {admin && edit ? 
                  <React.Fragment>
                    <TextField style={{ marginRight: 10 }} id={"nameShared"} label="Text" value={nameShared} variant="outlined" onChange={(event) => this.onChangeInput(event)}/>
                    <TextField id={"descriptionShared"} multiline rowsMax="4" label="Beskrivning" value={descriptionShared} variant="outlined" onChange={(event) => this.onChangeInput(event)}/>
                  </React.Fragment>
                  :
                  <React.Fragment>
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox color="primary" onChange={(event) => this.updateShared(event)} /> 
                    }
                    label={settings.nameShared}
                    labelPlacement="end"
                  />

                  <Tooltip title={
                        <React.Fragment>
                          <Typography variant="caption">{settings.descriptionShared}</Typography>
                        </React.Fragment>
                      }>
                    <Question />
                  </Tooltip>
                  </React.Fragment>

                }
                  
                </div>
                {admin && 
                <React.Fragment>
                  {edit ?
                     <Button style={{cursor: 'pointer', marginRight: 10, marginTop: 20 }} variant="outlined" color="primary" onClick={() => this.saveToRedux()}> Klar </Button>
                     :
                     <Button style={{cursor: 'pointer', marginRight: 10}} variant="outlined" color="primary" onClick={() => this.changeEditMode()}> Redigera </Button>
                  }
                  </React.Fragment>
                }
              </FormControl>
            </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = ({ answerModelReducer }) =>  ({
        answerModelReducer
  });

const mapDispatchToProps = (dispatch) => ({
    changeSettings: (key, settingsName, value) => dispatch(changeSettings(key, settingsName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareOptionsPage)
