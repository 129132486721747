import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import FormPage from '../partials/FormPage'

import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';  

import { ReactComponent as Logo } from '../../img/plus-logo.svg';


class EditTime extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {

    this.setState({value: event.target.value});
    
  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  checkDuplicates(){
    console.log(this.props.timepoints)
    let okay = false;
    if(this.props.timepoints.includes(this.state.value)) okay = true;
    return okay;
  }

  render() {

    return (
      <React.Fragment>
        <div style={{
          height: 160,
          width: 300,
          marginTop: 20,
          marginBottom: 40,
          boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
          padding: 20,
          paddingTop: 0,
        }}>

        <form style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                height: '100%'
              }}
        >
          <div style={{
            height: 10, 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'flex-end', 
            fontSize: 20,
            cursor: 'pointer',
          }} onClick={() => this.props.close()}>
            X
          </div>
          <TextField id="time" placeholder="Tillfälle" type="text" inputProps={200} onChange={(e) => this.handleChange(e)}/>
          {this.checkDuplicates() && <p style={{ alignSelf: 'flex-start', marginBottom: 10, fontSize: 14, color: 'red'}} >Namnet måste vara unikt</p>}
          <Button disabled={this.state.value == "" || this.checkDuplicates()} variant="outlined" color="primary" onClick={() => this.props.save(this.state.value)}>
            Spara
          </Button>
        </form>
        </div>
      </React.Fragment>
      );
  }
}

export default EditTime


