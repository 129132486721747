import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import {connect} from 'react-redux'
import { changeTimepointTasks, changeExtraTasks, changeSpecificTasks, changeLogoUrl } from '../../actions/backendDataAction'

import { ReactComponent as Trashcan } from '../resources/img/trashcan.svg';


class EditLogoUrl extends React.Component {

    state = {
        url: ""
    }


    constructor(props){
        super(props);
    }

    componentWillMount(){
        this.setState({
            url: this.props.logoUrl
        })
    }

    updateUrl(event){
        this.setState({
            url: event.target.value
        })
    }

    saveChanges(){
        this.props.changeLogoUrl(this.state.url)
        this.props.closeEdit();
    }

    

    

    render() {
        return (
            <div style={{ display: 'flex', marginTop: 20 }}>
                <TextField id="Logo ur" label="Logo ur" type="text" variant="outlined" value={this.state.url} style={{  width: '100%' }} onChange={(event) => this.updateUrl(event)}/>
                <Button style={{cursor: 'pointer', backgroundColor: 'white', marginLeft: 10}} variant="outlined" color="primary" onClick={() => this.saveChanges()}> Klar </Button>
            </div>
        );
    }
}


const mapStateToProps = ({  }) =>  ({

  });

const mapDispatchToProps = (dispatch) => ({
    changeLogoUrl: (logo) => dispatch(changeLogoUrl(logo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLogoUrl)